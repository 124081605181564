import React from 'react'
import { motion } from "framer-motion"; 
import { makeStyles } from '@material-ui/core/styles';
import {inject,observer} from 'mobx-react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from "recompose/compose";
import { navigate } from "gatsby"

import MenuToggle from '@src/components/tablet/MenuToggle';
import {ReactComponent as SearchLocation} from '@src/assets/svgIcons/Search location.svg'
import {ReactComponent as Star} from '@src/assets/svgIcons/Star.svg'
import {ReactComponent as Message} from '@src/assets/svgIcons/Message a.svg'
import {ReactComponent as Me} from '@src/assets/svgIcons/Account.svg'
import Typography from '@material-ui/core/Typography';
import {typography} from '@src/theme/theme'

const sidebar = {
  open: {
    x:0,
    display:'block',
  },
  closed: {
    x:550,
    transitionEnd:{
      display:'none'
    }
  }
};

const list = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const listItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
    closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}
  

const MobileMenu = (props) => {
  const {uiStore} = props
  const {
    openTabletMenu,
    setOpenTabletMenu,
    setExplorePageCurrentTab,
    isMessagePanelVisible,
    setIsMessagePanelVisible
  } = uiStore
  const styles = useStyles()

  const lists=[
    {
      Icon:SearchLocation,
      title:'Explore',
      onClick:()=>{
        setExplorePageCurrentTab('explore')
        setOpenTabletMenu(false)
        navigate('/exploreList')
      }
    },
    {
      Icon:Star,
      title:'Collection',
      onClick:()=>{
        setExplorePageCurrentTab('collections')
        setOpenTabletMenu(false)
        navigate('/exploreList')
      }
    },
    {
      Icon:Message,
      title:'Message',
      onClick:()=>{
        setExplorePageCurrentTab('messages')
        setOpenTabletMenu(false)
        setIsMessagePanelVisible(!isMessagePanelVisible)
      }
    },
  ]
  return (
    <motion.div 
      initial={false}
      animate={openTabletMenu ? "open" : "closed"}
      className={styles.root}
      variants={sidebar}
      transition={{duration:0.2}}
    >
      <div className={styles.header}>
        <img src={require('@src/images/TEAMLINK-logo.png')} className={styles.img}/>
        <MenuToggle isOpen={openTabletMenu} toggle={()=>setOpenTabletMenu(!openTabletMenu)}/>
      </div>
      <div className={styles.list}>
        <motion.ul variants={list} className={styles.ulStyle}>
          {lists.map(({title,Icon,onClick},i)=>
            <motion.li variants={listItem} className={styles.listStyle} key={title}>
              <div className={styles.alignH} onClick={onClick}>
                <Icon height={30} width={30} fill={'#000000'}/>
                <Typography className={styles.title}>{title}</Typography>
              </div>
              <hr className={styles.divider}/>
            </motion.li>
          )}
        </motion.ul>
      </div>
    </motion.div>
  )
}

const useStyles = makeStyles(theme=>({
    root:{
        position:'absolute',
        top:0,
        bottom:0,
        right:0,
        height:'100vh',
        width:'100%',
        background: 'white',
        WebkitBoxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        MozBoxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        boxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        zIndex:10,
        padding:28
    },
    img:{
        height:40,
    },
    header:{
        ...hBox('center','space-between'),
    },
    title:{
        marginLeft:24,
        ...typography.h4,
        fontSize:26
    },
    alignH:{
        ...hBox('center')
    },
    listStyle:{
        listStyle:'none',
        marginTop:24,
        marginBottom:24
    },
    ulStyle:{
        paddingLeft:0
    },
    list:{
        marginTop:32
    },
    divider:{
        marginTop:24
    }
}))

export default compose(inject('uiStore'), observer)(MobileMenu);
