import React, { useState } from "react";
import {inject,observer} from 'mobx-react';
import compose from 'recompose/compose';
import DehazeIcon from '@material-ui/icons/Dehaze';
import {hBox} from "gx-web-ui/utils/flex";
import {Link} from "gatsby";
import random from 'lodash/random';

import UserAvatarButton from '@src/components/common/UserAvatarButton';
import MenuToggle from '@src/components/tablet/MenuToggle';
import MessageMenu from "@src/components/message/MessageMenu";
import MobileMenu from './MobileMenu';
import MePanel from './setting/MePanel';

import TEAMLINKBlackLogo from '../../assets/icons/TeamlinkLogoBlack.svg';

import './MobileLayout.less';

const MobileHeadBar = (props) => {
  const { appStore, uiStore } = props;
  const { loggedInUser, heartBeatFlag } = appStore;
  const {setOpenTabletMenu, openTabletMenu, checkLogin, isMessagePanelVisible, 
    setIsMessagePanelVisible, switchMeSetting, isMeSettingOpen, setIsMeSettingOpen } = uiStore

  return (
    <div className="m-header-bar">
      {/* <DehazeIcon /> */}
      <MenuToggle isOpen={openTabletMenu} toggle={()=>setOpenTabletMenu(!openTabletMenu)} />
      <Link style={{...hBox('center')}} to="https://www.teamlink.com.au">
        <img src={TEAMLINKBlackLogo} className="logo" />
      </Link>
      {loggedInUser? 
        <div className="login">
          <UserAvatarButton onClick={()=>switchMeSetting()} avatar={`${loggedInUser.avatar}?rand=${random(1000)}`}/>
        </div> :
        <div className="login" onClick={checkLogin(()=>{})}>Log In</div>
      }
      <MobileMenu/>
      <MessageMenu
        style={{zIndex:100, top: 0}} 
        visible={isMessagePanelVisible} 
        setVisible={()=>{
          setIsMessagePanelVisible(false)
        }}
      />
      <MePanel/>
    </div>
  )
}

export default compose(inject('appStore','uiStore'), observer)(MobileHeadBar);