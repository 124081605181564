import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import IconButton from '@material-ui/core/IconButton'

import Close from '@material-ui/icons/Close'
import {hBox,vBox} from "gx-web-ui/utils/flex";

import { Grow,Paper, Divider, Button } from '@material-ui/core';
import { Menu } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


import CustomMenuItem from '@src/components/common/CustomMenuItem'
import {PersonOutline,PeopleAltOutlined,EventNote} from '@material-ui/icons';
import {LogoutOutlined} from '@ant-design/icons'
import GooglePlayDownloadBtn from '@src/components/common/GooglePlayDownloadBtn'
import AppStoreDownloadBtn from '@src/components/common/AppStoreDownloadBtn'
import CustomTabItem from '@src/components/common/CustomTabItem'
import MyProfilePanel from './MyProfilePanel'
import MySettingsPanel from '@src/components/setting/MySettingsPanel'
import MyDealsPanel from '@src/components/setting/MyDealsPanel'
import MyAgentsPanel from '@src/components/setting/MyAgentsPanel'
import MyInspectionsPanel from '@src/components/setting/MyInspectionsPanel'
import MyLeasingPanel from '@src/components/setting/MyLeasingPanel'

import { ReactComponent as Account } from '@src/assets/svgIcons/Account.svg'
import { ReactComponent as Logout } from '@src/assets/svgIcons/Logout.svg'
import { ReactComponent as Settings } from '@src/assets/svgIcons/Settings.svg'
import { ReactComponent as Work } from '@src/assets/svgIcons/Work.svg'
import { ReactComponent as LeasingApplication } from '@src/assets/svgIcons/LeasingApplication.svg'
import { ReactComponent as Group } from '@src/assets/svgIcons/Group.svg'
import { ReactComponent as Calendar } from '@src/assets/svgIcons/Calendar.svg'


import {Tabs} from 'antd'

const useStyles = makeStyles((theme) => ({
    root: {
    //   padding: '2px 4px',
        height : 42,
        padding : 4,
        width : "100%",
        display: 'flex',
        alignItems: 'center',
        background:'transparent',
        borderRadius : 4,
        border: '1px solid #ccc',
    },
    input: {
        paddingLeft:16,
        background:'transparent',
        flex: 1,
        '&::placeholder': {
            opacity: 0.3,
        }
    },
    iconButton: {
        padding: 10,
        borderRadius : 4,
        border : '1px solid #ccc',
    },
    divider: {
        height: 28,
        margin: 4,
    },
    icon : {
        height : 48,
        width : 48
    }
}));

const { TabPane } = Tabs;

const MeSettingComponent = (props) => {


    const { appStore,uiStore } = props

    const {setIsMeSettingOpen, isProfileDetailOpen, setIsProfileDetailOpen} = uiStore

    const {loggedInUser,getLoggedInPerson_,loggedInPerson} = appStore

    const [activeKey, setActiveKey] = useState(null)

    const classes = useStyles()

    

    

    const sampleLogout = () =>{
        setIsMeSettingOpen(false)
        setIsProfileDetailOpen(false)
        appStore.logout()
    }
    
    const onMenuItemClick = (key) =>{
        if(key == 7){
            sampleLogout()
            return
        }
        setIsProfileDetailOpen(true)
        setActiveKey(key)
    }

    
    const onCloseFunction = ()=>{

        setIsProfileDetailOpen(false)
        setIsMeSettingOpen(false)

    }

    const menuItems = [
        {
            key : 1,
            icon : <Account style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MyProfilePanel data={loggedInPerson} label="My profile" closeFunction = {onCloseFunction}/>,
            label : "My profile"
        },
        {
            key : 2,
            icon : <Settings style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MySettingsPanel  label="Setting" closeFunction = {onCloseFunction}/>,
            label : "Setting"
        },
        {
            key : 3,
            icon : <Group style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MyAgentsPanel  label="Agents" closeFunction = {onCloseFunction}/>,
            label : "Agents"
        },
        {
            key : 4,
            icon : <Calendar style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MyInspectionsPanel  label="Inspections" closeFunction = {onCloseFunction}/>,
            label : "Inspections"
        },
        {
            key : 5,
            icon : <Work style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MyDealsPanel  label="Deals" closeFunction = {onCloseFunction}/>,
            label : "Deals"
        },
        {
            key : 6,
            icon : <LeasingApplication style={{width:24, height:24,color : '#80808066'}}/>,
            content : <MyLeasingPanel  label="Leasing applications" closeFunction = {onCloseFunction}/>,
            label : "Leasing applications"
        },
        {
            key : 7,
            icon : <Logout style={{width:24, height:24,color : '#80808066'}}/>,
            content : "<Button color='primary' onClick={sampleLogout}>Logout</Button>",
            label : "Logout"
        }
    ]

    return(
        // <div>
        <React.Fragment>
            {
                !isProfileDetailOpen ? <div>
                    <div style={{height : 48}}>
                        <IconButton disableRipple onClick={()=>{setIsMeSettingOpen(false)}} style = {{background : 'transparent', position :'absolute', right : 0}}>
                            <Close />
                        </IconButton>
                    </div>
                    {
                        menuItems.map(item=>
                            <div key={item.key}>
                                <CustomMenuItem icon={item.icon}  label={item.label} onClick={()=>onMenuItemClick(item.key)} />
                            </div>
                        )
                    }
                    {/* <div style={{...hBox('center','center'), marginTop : `calc(23%)`}}>
                        <AppStoreDownloadBtn  style={{'background' : 'transparent', width : "60%"}} onClick={()=>console.log("developing")} />
                    </div>
                    <div style={{...hBox('center','center'), marginTop : 18}}>
                        <GooglePlayDownloadBtn style={{'background' : 'transparent', width : "60%"}}  onClick={()=>console.log("developing")} />
                    </div> */}
                </div>
                :
                <div style={{height : '100%'}}>
                    <div style={{...hBox('flex-start'),height : '100%'}}>
                        <div>
                            <div style={{height : 48}}>

                            </div>
                            {
                                menuItems.map(item=>
                                    <div key={item.key}>
                                        <CustomMenuItem icon={item.icon}  isActivated={ activeKey === item.key } onClick={()=>onMenuItemClick(item.key)} />
                                    </div>
                                )
                            }
                        </div>
                        <div style={{width : '100%', height : '100%', background : '#ffffff'}}>
                            {menuItems[activeKey-1] && 
                            menuItems[activeKey-1]['content']}
                        </div>
                        
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default compose(inject('appStore','uiStore'),observer)(MeSettingComponent)