import React,{ useState,useRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import Countdown from "react-countdown";
import _, { set } from 'lodash'
import {hBox,vBox} from "gx-web-ui/utils/flex";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '@src/components/common/Loading';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ButtonBase from '@material-ui/core/ButtonBase'

import SidePanelHeader from './SidePanelHeader'
// import "antd/dist/antd.css";
import ConfirmIconButton from '@src/components/common/ConfirmIconButton'
import CustomTextPairDisplay from '@src/components/common/CustomTextPairDisplay'
import EditIconButton from '@src/components/common/EditIconButton'
import AvatarEditButton from '@src/components/common/AvatarEditButton'
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { ReactComponent as Edit } from '@src/assets/svgIcons/Edit.svg'
import { ReactComponent as Send } from '@src/assets/svgIcons/Send.svg'
import { ReactComponent as ArrowRight } from '@src/assets/svgIcons/ArrowRightWhite.svg'
// import ArrowRight from '@src/assets/svgIcons/ArrowRight.svg'
import VerifyEmailComponent from '@src/components/setting/VerifyEmailComponent'

import {renderMobile,renderDate} from '@src/utils/formatters'

import {DatePicker,message} from 'antd'
import {isValidMobilePhone} from '@src/utils/typeCheck' 


const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    },
    radio : {
        color: '#c5c5c5',
        '&$checked': {
            color: "#fa6400",
        },
    },
    checked: {}

    
}))


const  MyProfilePanel= (props) => {


    const {appStore, label, closeFunction} = props

    const {loggedInPerson,getLoggedInPerson_,confirmUpdateUserProfile_} = appStore
    const [isEditing, setIsEditing] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [data,setData] = useState(null)
    const [gender, setGender] = useState('Male')
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [isChangeMobile, setIsChangeMobile] = useState(false)
    const [newMobile, setNewMobile] = useState('')
    const [code , setCode] = useState('')
    const [countDownTime, setCountDownTime] = useState(Date.now() + 59000)
    const [userVerifyId, setUserVerifyId] = useState(null)

    const classes = useStyle()

    // let code = ""
    // let newMobile = ""

    const countDownRef = useRef()

    useEffect(() => {
        if(isLoading){
            getLoggedInPerson_().then((res)=>{
                
                setData(res)

            })
        }
        
     }, [isLoading])


    // const changeCode = (value) =>{
    //     code = value
    // }

    // const changeMobile = (value) =>{
    //     newMobile = value
    // }

    const onClickSend =(ref) =>{
        setCountDownTime(Date.now() + 59000)
        setIsCodeSent(true)
        appStore.client.post('send-code',{
            mobile : _.get(data,'mobile'),
            reason : 'resetMobile'
        }).then(({response})=>{

            console.log(response)
            setUserVerifyId(response.id)
            
        }).catch(error=>{
            message.error(error)
        })
        // ref.current.start()
    }


    const onClickBack = () =>{
        setIsCodeSent(false)
        setIsChangeMobile(false)
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            // setIsCodeSent(false)
            return <div style={{...hBox('center')}}>
                <Typography style={{'color' : 'rgb(0,0,0,0.2)'}} variant = "body2" >Not received ?</Typography>
                <Link variant = "body2" style={{'textDecoration': 'underline','textDecorationColor': 'black'}} onClick={()=> {onClickSend()} } >Resend</Link>
            </div>
        } else {
          // Render a countdown
          return <span>{seconds}s</span>;
        }
    };

    const onValueChange = (path,value)=>{

        const newData = _.set(data,path,value)
        setData({...data, ...newData})

        console.log(data)
    }

    const onConfirm = () =>{
        const body = {
            firstName : _.get(data,':person.firstName'),
            lastName : _.get(data,':person.lastName'),
            dob : _.get(data,':person.dob'),
            email : _.get(data,'email'),
            gender : _.get(data,':person.gender')
        }
        confirmUpdateUserProfile_(body)
        setIsEditing(false)
    }

    const handleVerify = () =>{
        appStore.client.post(['change-mobile',userVerifyId],{
            mobile : newMobile,
            code : code,
            reason : 'resetMobile'
        }).then(({response})=>{
            setIsChangeMobile(false)
            setCountDownTime(Date.now() + 59000); 
            setData({...set(data,'mobile', newMobile)})
            console.log(response)
        }).catch(error=>{
            message.error(error)
        })
    }

    const isValid = () =>{
        return code.length === 6 && isValidMobilePhone(newMobile) ? true : false
    }
    return(
        
        <div style={{height : "100%"}}>  
            <SidePanelHeader label={isChangeMobile ? "Change mobile" : label} closeFunction = { closeFunction } backFunction={isChangeMobile ? onClickBack  : null} />
            <div style = {{padding : 18,width : 'calc(100% - 36px)', background : '#ffffff',overflow : 'overlay', height : "calc(100% - 68px)"}}>
                <Loading loading = {!loggedInPerson}>
                {
                    isChangeMobile ? <React.Fragment>
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <Typography variant='subtitle1' style={{color : '#c5c5c5'}} >
                                Mobile
                            </Typography>
                            <TextField variant='outlined' value={renderMobile(_.get(data,'mobile') || '','+61',true)} disabled={true}  inputProps={{readOnly : true}} style={{width : '100%',background : '#c5c5c5'}}/>
                        </div>
                        {
                            isCodeSent ?
                                <React.Fragment>
                                <div style = {{...hBox('center','space-between')}}>
                                    <Typography style={{'color' : 'rgb(0,0,0,0.2)'}} variant = "body2" >Code has been sent</Typography>
                                    <Countdown  key={countDownTime} date={countDownTime} renderer={renderer} ref={countDownRef}/>
                                </div>
                                <div style={{marginTop : 12, marginBottom : 12}}>
                                    <Typography variant='subtitle1' style={{color : '#c5c5c5'}} >
                                        Input the code
                                    </Typography>
                                    <TextField variant='outlined' onChange={e=>setCode(e.target.value)}  disabled={!isCodeSent}   style={{width : '100%' ,background :isCodeSent ? '#ffffff' : '#c5c5c5'}}/>
                                </div>
                                <div style={{marginTop : 12, marginBottom : 12}}>
                                    <Typography variant='subtitle1' style={{color : '#c5c5c5'}} >
                                        New mobile
                                    </Typography>
                                    <TextField variant='outlined' onChange={e=>setNewMobile(e.target.value)}  disabled={!isCodeSent}   style={{width : '100%' ,background :isCodeSent ? '#ffffff' : '#c5c5c5'}}/>
                                </div>
                                <div style={{marginTop : 24}}>
                                    <ButtonBase disabled={!isValid()} onClick={handleVerify}style={{width : "100%", background : isValid() ? 'black' : 'rgb(0,0,0,0.3)',borderRadius : 4, height : 37}}>
                                        <span style={{'color' : 'white'}}>Next</span>
                                        <ArrowRight name='next' style={{ marginLeft : 12}}/>
                                    </ButtonBase>
                                </div>
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <div style = {{...hBox('center','flex-end')}}>
                                        <IconButton onClick={()=>onClickSend()} style = {{height : 40, width : 40}}>
                                            <Send />
                                        </IconButton>
                                        <Typography variant = "body2" >Send code</Typography>
                                    </div>
                                </React.Fragment>
                        }
                        
                    </React.Fragment>
                    :
                    <React.Fragment>
                    {
                        isEditing ? 
                        <div>
                            <div style={{marginTop : 12, marginBottom : 12}}>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}}>
                                    First name
                                </Typography>
                                <TextField variant='outlined' value={ _.get(data, ':person.firstName') || ''} onChange={e=>onValueChange(':person.firstName',e.target.value)} style={{width : '100%'}}/>
                            </div>
                            <div style={{marginTop : 12, marginBottom : 12}}>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}}>
                                    Last name
                                </Typography>
                                <TextField variant='outlined' value={_.get(data, ':person.lastName') || ''}  onChange={e=>onValueChange(':person.lastName',e.target.value)}style={{width : '100%'}}/>
                            </div>
                            <div>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}}>
                                    Gender
                                </Typography>
                                <FormControl component='fieldset'>
                                    <RadioGroup row aria-label='gender' name='Gender' value={_.get(data, ':person.gender') || 'M'} onChange={(e)=>onValueChange(':person.gender',e.target.value)} >
                                        <FormControlLabel value='M' control={<Radio classes={{root : classes.radio, checked: classes.checked}} /*style = {{color : gender === 'M' && '#FA6400'}}*/ /> } label='Male'/>
                                        <FormControlLabel value='F' control={<Radio classes={{root : classes.radio, checked: classes.checked}}  /*style = {{color : gender === 'F' && '#FA6400'}}*/ />} label='Female'/>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div style={{marginTop : 12, marginBottom : 12}}>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}}>
                                    Date of birth
                                </Typography>
                                <DatePicker placeholder="Select date" inputReadOnly = {true} disabledDate={d=>d.isAfter(moment()) }onChange={(date,dateString)=>{
                                    {/* console.log(dateString) */}
                                    if(date){
                                        let temp = _.set(data,':person.dob',date.toISOString())
                                        setData({...data, ...temp})
                                    }
                                }} size='middle' defaultValue={_.get(data, ':person.dob') ? moment(_.get(data, ':person.dob')):null} style={{fontColor : 'black', width : '100%',height : 37, border:'1px solid rgb(0,0,0,0.2)'}}/>
                            </div>
                            <div style={{marginTop : 12, marginBottom : 12}}>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}}>
                                    Email
                                </Typography>
                                <TextField variant='outlined' value={_.get(data, 'email') || ''} onChange={(e)=>onValueChange('email',e.target.value)} style={{width : '100%'}}/>
                            </div>
                            <div style={{marginTop : 12, marginBottom : 12}}>
                                <Typography variant='subtitle1' style={{color : 'rgb(0,0,0,0.5)'}} >
                                    Mobile
                                </Typography>
                                <TextField variant='outlined' value={renderMobile(_.get(data,'mobile') || '','+61',true)} onChange={(e)=>onValueChange('mobile',e.target.value)} disabled={true}  inputProps={{readOnly : true}} style={{width : '100%',background : '#c5c5c5'}}/>
                            </div>
                            <div style = {{...hBox('center','flex-start')}}>
                                <IconButton disableRipple onClick={()=>setIsChangeMobile(true)} style = {{background : 'transparent', height : 40, width : 40}}>
                                    <Edit />
                                </IconButton>
                                <Typography variant = "body2" >Change mobile</Typography>
                            </div>
                            <div style={{...hBox('center','space-between'),marginTop : 12, marginBottom : 12}}>
                                <ButtonBase onClick={()=>setIsEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid #c5c5c5',borderRadius : 4, height:42}}>
                                    <span style={{'color' : 'rgb(0,0,0,0.5)'}}>Cancel</span>
                                </ButtonBase>
                                <ConfirmIconButton onClick={onConfirm} style={{ width : "45%"}}/>
                            </div>
                        </div>
                        :
                        <div>
                            <div style={{...hBox('center','center')}}>
                                <div style={{...vBox('center','center')}}>
                                    <AvatarEditButton avatar={ _.get(data,'avatar') || ''} hasIcon={true} />
                                    <Typography variant='h6'>
                                        { _.get(data,'name') || ''}
                                    </Typography>
                                </div>
                            </div>
                            <CustomTextPairDisplay label={'First name'} content={_.get(data, ':person.firstName') || ''} />
                            <CustomTextPairDisplay label={'Last name'} content={_.get(data, ':person.lastName') || ''} />
                            <CustomTextPairDisplay label={'Gender'} content={_.get(data, ':person.gender') ?  _.get(data, ':person.gender') : 'M'} />
                            <CustomTextPairDisplay label={'Date of birth'} content={_.get(data, ':person.dob') ? renderDate(_.get(data, ':person.dob')):''} />
                            <VerifyEmailComponent  email={_.get(data,'email') || ''} isEmailVerified={_.get(data,'isEmailVerified')}/>
                            <CustomTextPairDisplay label={'Mobile'} content={renderMobile(_.get(data,'mobile') || '','+61',true)} />
                            <EditIconButton onClick={()=>setIsEditing(true)} style={{width : "100%"}}/>
                        </div>
                    }
                </React.Fragment>
                }
                </Loading>
            </div>
        </div>
        // </Loading>
    )
}

export default compose(inject('appStore','uiStore'),observer)(MyProfilePanel)