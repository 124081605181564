import * as React from "react";
import { motion } from "framer-motion";
import ButtonBase from '@material-ui/core/ButtonBase'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle,isOpen,...other }) => {
    const styles = useStyles()
    return <ButtonBase onClick={toggle} disableRipple className={styles.btn} {...other}>
        <motion.svg
            initial={false}
            animate={isOpen ? "open" : "closed"} 
            width="23" 
            height="23" 
            viewBox="0 0 23 23">
        <Path
            variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" }
            }}
        />
        <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 }
            }}
            transition={{ duration: 0.1 }}
        />
        <Path
            variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" }
            }}
        />
        </motion.svg>
    </ButtonBase>
}

const useStyles = makeStyles(theme=>({
    btn:{
        paddingLeft:8,
        paddingRight:8,
    }
}))


export default MenuToggle